@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;

/* stylelint-disable a11y/media-prefers-reduced-motion */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  background: commonStyles.$true-black;
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
/* stylelint-enable a11y/media-prefers-reduced-motion */

@media screen and (prefers-reduced-motion: reduce) {
  .overlay {
    transition: none;
  }
}

.show {
  height: 100vh;
  opacity: 0.6;
}
