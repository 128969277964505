@import '../Header.config';

button.container {
  // overrides for alliance ui button styles
  border: 0;
  padding: 0;
  background-color: transparent;
  color: black;
  text-transform: none;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: transparent;
    color: $getty-link-hover-color;
    transition-duration: 0.3s;

    svg {
      fill: $getty-link-hover-color;
      transition-duration: 0.3s;
    }
  }

  &:focus:not(:focus-visible) {
    /* stylelint-disable a11y/no-outline-none */
    outline: none;
    /* stylelint-enable */
  }
}

.hamburgerText {
  margin-left: 0.625em;
  font-size: 0.875em;
  letter-spacing: 0;
}

.leftPanel {
  width: 100%;

  @media #{$medium-up} {
    width: $panel-width-desktop;
  }
}
