@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

@mixin active-state($text-color, $background: null) {
  &:hover,
  &:focus,
  &.isExpanded,
  li:hover > & {
    color: $text-color;

    @if $background {
      background: $background;
    }
    /* stylelint-disable no-descending-specificity */
    .icon svg,
    .icon path {
      fill: $text-color;
    }
    /* stylelint-enable no-descending-specificity */
  }
}

a.navLink,
button.navLink {
  appearance: none;
  border: none;
  background: none;
  color: gettyTheme.$gi-black;

  &:visited {
    color: gettyTheme.$gi-black;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }
}

a.oldVariant,
button.oldVariant {
  &:hover,
  &:focus {
    color: gettyTheme.$primary;

    .icon svg,
    .icon path {
      fill: gettyTheme.$primary;
    }
  }
}

a.linkVariant,
button.linkVariant {
  @include active-state($text-color: gettyTheme.$primary);
}

.arrowIcon {
  margin-left: 4px;
}

a.pillVariant,
a.linkVariant,
button.pillVariant,
button.linkVariant {
  display: inline-flex;
  align-items: center;
  height: commonStyles.em(40);
  padding: 0 $nav-link-padding-x-mobile;
  font-size: $getty-header-font-size-mobile;
  line-height: 1.2em;
  font-weight: 500;

  @media #{$large-up} {
    font-size: $getty-header-font-size;
    font-weight: 400;
  }

  @media #{$large-up} and (hover: hover) {
    padding: 0 $nav-link-padding-x;
  }

  &.isExpanded {
    .arrowIcon {
      fill: commonStyles.$true-white;
      transform: rotate(180deg);
    }
  }
}

a.pillVariant,
button.pillVariant {
  border-radius: 28px;

  .text {
    color: inherit;
  }

  @include active-state(
    $text-color: commonStyles.$true-white,
    $background: gettyTheme.$gi-black
  );
}

.iconWithText {
  margin-right: 4px;
}
