@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

a.link {
  // overrides alliance ui link classes
  border: 0;
  color: $black;
  display: inline-flex;
  padding: 0;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    color: $getty-link-hover-color;
    text-decoration: none;
    transition-duration: 0.3s;

    svg {
      fill: $getty-link-hover-color;
      transition-duration: 0.3s;
    }
  }
}

// This was copied and trimmed from NavLinkOrButton.module.scss.  If Getty SignIn emphasis is producionized, we should work to better reduce duplication
// https://app.clickup.com/t/86b32k6hw.

@mixin active-state($text-color, $background: null) {
  &:hover,
  &:focus {
    color: $text-color;
    text-decoration: none;

    @if $background {
      background: $background;
    }
    /* stylelint-disable no-descending-specificity */
    .icon svg,
    .icon path {
      fill: $text-color;
    }
    /* stylelint-enable no-descending-specificity */
  }
}

a.pillVariant {
  color: gettyTheme.$gi-black;
  display: inline-flex;
  align-items: center;
  height: 45px;
  width: 45px; // for the cart we want a circle not a pill.
  padding: 0 $nav-link-padding-x-mobile;
  font-size: $getty-header-font-size-mobile;
  line-height: 1.2em;
  font-weight: 500;
  border-radius: 28px;
  border-width: 0;

  .text {
    color: inherit;
  }

  @include active-state(
    $text-color: commonStyles.$true-white,
    $background: gettyTheme.$gi-black
  );

  @media #{$large-up} {
    font-size: $getty-header-font-size;
    font-weight: 400;
  }

  @media #{$large-up} and (hover: hover) {
    padding: 0;
  }
}

.iconWrapper {
  display: flex;
  position: relative;
}

.numberOfItems {
  $offset: -5px;

  min-width: 18px;
  height: 18px;
  background-color: $alert-color;
  top: $offset;
  position: relative;
  left: $offset;
  margin-right: $offset;
}

.badge {
  align-items: center;
  border-radius: 1em;
  color: $gi-white;
  display: flex;
  font-size: 0.75em;
  justify-content: center;
  padding: 2px 5px;
}

.cartText {
  margin-left: 0.1em;
  padding-top: 0.01em;
}

.emptyCart {
  margin-right: 8px;
}
