@import '../Header.config';

.popupContainer {
  padding: 1rem 2.5rem;
}

.minimalContainer {
  border-bottom: 1px solid $grey;
  margin-bottom: 60px;
  padding: 1rem 2.5rem;
}
