@import '../Header.config';

.list {
  font-size: 16px;
  padding: 40px 20px 0;

  li {
    margin-top: 24px;
  }

  li:last-child {
    margin-top: 0;
  }
}

.accountInfo {
  margin-top: 32px;

  // below is an override for legacy pages like PnP and Collab
  font-family: $primary-font-family;

  .headingGroup {
    text-align: center;
  }

  .header {
    font-size: 24px;
    line-height: 28px;
  }

  .username {
    padding-top: 8px;
    font-size: 16px;
  }

  .linksGroup {
    margin-top: 20px;

    .link {
      padding: 12px 0;
      font-size: 16px;
    }
  }
}

a.link {
  color: unset;
  text-decoration: none;
  display: block;
  padding: 0.75em 0;

  &:hover,
  &:focus {
    color: $getty-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }

  &:visited {
    color: $gi-black;

    &:hover,
    &:focus {
      color: $getty-link-hover-color;
    }
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  a.signOut {
    transition: none;
  }
}

a.signOut {
  margin-top: 100px;

  &:visited {
    color: $gi-black;
  }
}

button.boardsLink {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: black;
  font-size: 16px;
  display: flex;
  text-transform: none;

  @media #{$large-up} {
    display: none;
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: transparent;
    color: $getty-link-hover-color;
  }
}

.betaBadge {
  background-color: #0055d6;
  color: $gi-white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  padding: 2px 4px;
  margin-left: 8px;
  position: absolute;
}
