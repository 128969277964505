@import '../Header.config';

.content {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.isDesktop {
    @media #{$large-up} {
      flex-direction: row;
      flex-grow: 1;
    }
  }
}

.overview {
  flex-shrink: 0;

  .isDesktop & {
    @media #{$large-up} {
      &:only-child {
        flex-grow: 1;
      }

      &:not(:only-child) {
        width: $getty-meganav-column-width;
      }
    }
  }
}

.pillsWrapper {
  display: flex;
  flex-direction: column;

  .isDesktop & {
    @media #{$xlarge-up} {
      flex-direction: row;
      flex-grow: 1;
    }
  }
}

.pillsWrapper .pillsContainer {
  width: 100%;

  .isDesktop & {
    @media #{$xlarge-up} {
      flex-grow: 1;
      max-width: 1280px;
    }
  }
}
