@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

button.pillVariant svg {
  margin-right: 0.8em;
  transform: scale(1.05);
}

button.container svg {
  margin-right: 0.8em;
  transform: scale(1.05);
}

button.container {
  background-color: transparent;
  border: 0;
  display: inline-flex;
  font-size: $getty-header-font-size; // override alliance ui button font size
  letter-spacing: 0;
  padding: 0;
  line-height: unset;
  height: unset;
  text-transform: none;

  &:active:not([disabled]),
  &:focus:not([disabled]) {
    background-color: transparent;
    color: $gi-black;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 0.3s;

    svg {
      fill: $gi-black;
      transition-duration: 0.3s;
    }
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: transparent;
    color: $getty-link-hover-color;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 0.3s;

    svg {
      fill: $getty-link-hover-color;
      transition-duration: 0.3s;
    }
  }
}

// This was copied and trimmed from NavLinkOrButton.module.scss.  If Getty SignIn emphasis is producionized, we should work to better reduce duplication
// https://app.clickup.com/t/86b32k6hw.

@mixin active-state($text-color, $background: null) {
  &:hover,
  &:focus {
    color: $text-color;

    @if $background {
      background: $background;
    }
    /* stylelint-disable no-descending-specificity */
    .icon svg,
    .icon path {
      fill: $text-color;
    }
    /* stylelint-enable no-descending-specificity */
  }
}

button.pillVariant {
  display: inline-flex;
  align-items: center;
  height: commonStyles.em(40);
  padding: 0 $nav-link-padding-x-mobile;
  font-size: $getty-header-font-size-mobile;
  line-height: 1.2em;
  font-weight: 500;
  border-radius: 28px;
  border-width: 0;

  .text {
    color: inherit;
  }

  @include active-state(
    $text-color: commonStyles.$true-white,
    $background: gettyTheme.$gi-black
  );

  @media #{$large-up} {
    font-size: $getty-header-font-size;
    font-weight: 400;
  }

  @media #{$large-up} and (hover: hover) {
    padding: 0 $nav-link-padding-x;
  }
}
