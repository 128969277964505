@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';

.container {
  padding: 0.5rem;

  @media #{$medium-up} {
    padding: 1rem 2.5rem;
  }
}

.innerContainer {
  position: relative;
}

.badge {
  position: absolute;
  background-color: #26a3fd;
  color: gettyTheme.$true-white;
  border-radius: 24px;
  font-size: 10px;
  height: 26px;
  line-height: 18px;
  padding: 4px 8px;
  align-self: center;

  @media #{$medium-up} {
    height: 41px;
    line-height: 25px;
    padding: 8px 16px;
    font-size: 14px;
  }
}
