@import '../Header.config';

.container {
  align-items: center;
  background-color: $gi-black;
  height: $thin-header-height;
  justify-content: space-between;
  padding-top: 1em;
  position: relative;
}

a.link {
  left: 50%;
  margin: 0 auto;
  position: absolute;
  transform: translate(-50%);
}

.signInButton a {
  margin-left: auto;
}

.desktop {
  display: none;

  @media #{$large-up} {
    display: block;
  }
}

.mobile {
  display: block;

  @media #{$large-up} {
    display: none;
  }
}

.accountContainer {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  line-height: unset;
  list-style: none;
  text-align: right;
}

.navList {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 0;
}

.accountNavItem {
  span > svg {
    margin-left: 0.5em;
  }

  @media #{$large-up} {
    a {
      margin-left: 1.5em;
    }
  }
}

.rightPanel {
  width: 100%;

  @media #{$medium-up} {
    width: $panel-width-desktop;
  }
}

.navListItem {
  margin-left: 1.25em;
}
