@import '../Header.config';

.list {
  display: contents;
}

.menuItem {
  width: $getty-meganav-column-width;
}

.listItem {
  display: contents;

  &:last-child .menuItem {
    margin-bottom: 24px;
  }
}
