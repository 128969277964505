@import '../Header.config';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.leftPanel {
  width: $sidebar-meganav-mobile-width;

  @media #{$medium-up} {
    width: $sidebar-meganav-tablet-width;
  }

  div.leftPanelRoot {
    padding: 48px 0 0;

    // Close button
    > button:first-child {
      top: 13px;
      right: 11px;

      // stylelint-disable-next-line max-nesting-depth
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

/* stylelint-disable a11y/no-outline-none */
.leftPanelRoot > button:first-child:focus:not(:focus-visible) {
  outline: none;
}
/* stylelint-enable a11y/no-outline-none */
.rightPanel {
  width: 100%;

  @media #{$medium-up} {
    width: $panel-width-desktop;
  }
}
