@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../../Header.config';

.container {
  background: linear-gradient(
      127deg,
      rgb(111 67 214 / 13%) 0%,
      rgb(111 67 214 / 0%) 100%
    ),
    $med-light-grey;
  padding: 32px 16px 48px 32px;
  flex-shrink: 0;
  max-width: 100%;
  line-height: 1.15 !important;
}

h6.title {
  margin: 0 0 19px;
  font-family: $primary-font-family;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
}

a.link {
  display: flex;
  font-size: 16px;
  margin-bottom: 7px;

  &:visited {
    color: gettyTheme.$primary;
  }

  &:first-of-type {
    padding-bottom: 15px;
    border-bottom: 1px solid $grey-40;
  }
}

.text {
  font-size: 14px;
  margin: 14px 0;
  font-family: $primary-font-family;
}

.container:only-child .text {
  font-size: 16px;
  margin: 20px 0;
  font-family: $primary-font-family;
}

.linkIcon {
  margin-left: 7px;
  fill: gettyTheme.$primary;
}
