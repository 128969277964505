@use '@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty/index' as gettyTheme;
@import '../Header.config';

.container {
  position: relative;
  overflow-x: hidden;
  height: 100%;
}

.list {
  padding-bottom: 32px;
}

button.backButton {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;

  &:focus:not(:focus-visible) {
    /* stylelint-disable a11y/no-outline-none */
    outline: none;
    /* stylelint-enable */
  }

  &:active:not([disabled]),
  &:focus:not([disabled]) {
    color: $getty-link-hover-color;
  }

  @media (hover: hover) {
    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      color: $getty-link-hover-color;
    }
  }

  // Reset leaking hospice style
  svg {
    fill: currentcolor;
  }
}

button.menuItem,
a.menuItem {
  padding-top: 11px;
  padding-bottom: 11px;
}

/* stylelint-disable a11y/media-prefers-reduced-motion */
.panels {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  height: 100%;
  transition: left $sidebar-meganav-slide-duration cubic-bezier(0.4, 0, 0.2, 1)
    0ms;

  &.disableAnimation {
    transition: none;
  }
}
/* stylelint-enable a11y/media-prefers-reduced-motion */

@media screen and (prefers-reduced-motion: reduce) {
  .panels {
    transition: none;
  }
}

@for $i from 2 through 5 {
  .slideToPanel#{$i} {
    left: -$sidebar-meganav-mobile-width * ($i - 1);

    @media #{$medium-up} {
      left: -$sidebar-meganav-tablet-width * ($i - 1);
    }
  }
}

// TODO: Remove once interim browse panel is replaced
.interimPanel {
  width: 350px;
}

button.interimBackButton {
  top: 32px;
}

.interim {
  margin-top: 24px;

  button.menuItem,
  a.menuItem {
    &:focus-visible,
    &:focus {
      border-color: commonStyles.$true-white;
      color: gettyTheme.$primary;
    }

    &:active,
    &.menuItemSelected {
      border-color: commonStyles.$true-white;
      background-color: commonStyles.$true-white;
      color: gettyTheme.$primary;
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        border-color: commonStyles.$true-white;
        background-color: commonStyles.$true-white;
        color: gettyTheme.$primary;
      }
    }
  }
}
