@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

.betaBadge {
  background-color: #0055d6;
  color: $gi-white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  padding: 2px 4px;
  margin-right: 4px;
}

.aiLink {
  color: gettyTheme.$dark-grey;
}

.hamburgerContainer {
  padding: 0 $nav-link-padding-x;
}
