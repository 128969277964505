@import '../Header.config';

.container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.container img {
  width: 120px;
  height: 38px;

  @media #{$medium-up} {
    width: 175px;
    height: 38px;
  }
}
