@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

.pillContainer {
  font-family: $primary-font-family;
  line-height: 1.15;
}

a.pill {
  display: flex;
  border-radius: 8px;
  border: 1px solid gettyTheme.$medium-grey;
  padding: 12px;
  color: commonStyles.$true-black;
  margin: 8px 0 0 8px;
  font-size: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: commonStyles.$true-black;
    color: commonStyles.$true-white;
  }
}
