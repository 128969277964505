@use '@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty/index' as gettyTheme;
@import '../../Header.config';

button.menuItem {
  appearance: none;
}

button.menuItem,
a.menuItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  padding: 9px 44px;
  color: commonStyles.$true-black;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid commonStyles.$true-white;
  background: commonStyles.$true-white;
  cursor: pointer;
  grid-column: 1;

  // Reset leaking hospice styles
  text-transform: none;
  letter-spacing: initial;

  &:hover,
  &.menuItemSelected {
    text-decoration: none;
  }

  .caret {
    flex-shrink: 0;
    flex-grow: 0;
    fill: commonStyles.$true-black;
  }

  &:focus,
  &:focus-visible {
    /* stylelint-disable a11y/no-outline-none */
    outline: none;
    /* stylelint-enable */
    text-decoration: none;
  }

  &:focus-visible {
    border-color: gettyTheme.$primary;
  }

  &.enableSelect {
    &:active,
    &.menuItemSelected {
      border-color: gettyTheme.$primary;
      background-color: gettyTheme.$primary;
      color: commonStyles.$true-white;

      &:focus-visible {
        border-color: gettyTheme.$primary-light;
      }
    }

    &:active .caret,
    &.menuItemSelected .caret {
      fill: commonStyles.$true-white;
    }

    @media (hover: hover) {
      &:hover:not(.menuItemSelected, :active),
      &:focus:not(.menuItemSelected, :active) {
        color: gettyTheme.$primary;

        // stylelint-disable-next-line max-nesting-depth
        .caret {
          fill: gettyTheme.$primary;
        }
      }
    }
  }

  &.disableSelect {
    &:hover,
    &:focus,
    &:focus-visible {
      border-color: gettyTheme.$primary;
      background-color: gettyTheme.$primary;
      color: commonStyles.$true-white;

      .caret {
        fill: commonStyles.$true-white;
      }
    }
  }
}

a.simplifiedMegaNav {
  padding: 16px 48px;
}
