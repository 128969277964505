@import '../Header.config';

.container {
  display: grid;
  grid-template-columns: $getty-meganav-column-width 1fr;
}

h5.title {
  margin: 0;
  padding: 32px 48px 16px;
  font-size: 21px !important; // legacy styles override
  font-weight: 700;
  line-height: normal;
  width: $getty-meganav-column-width;
  font-family: $primary-font-family;
}

.content {
  grid-column: 2;
  grid-row: 1 / 100;
}

.hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.ctaButton {
  margin-left: 35px;
}
