@import '../Header.config';

$template-loader-duration: 2s;

@media screen and (prefers-reduced-motion: reduce) {
  .container {
    animation: none;
  }
}

.container {
  padding-top: 40px;
  animation: fade-in-and-out $template-loader-duration both infinite;
}

.animationEnd {
  animation-iteration-count: 1;
}

.mainRow {
  height: 1.5em;
  max-width: 150px;
  background-color: $grey-20;
  margin: 0 auto 1em;
}

.row {
  background-color: $grey-20;
  height: 1em;
  margin: 0.75em;
}

@keyframes fade-in-and-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
