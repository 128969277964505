@import '../Header.config';

// Classnames correlate to header type (Default vs visualGps)

/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
.account {
  margin: 0;
  padding: 0;
}

.signInText {
  display: none;

  @media #{$large-up} {
    display: block;
    white-space: nowrap;
  }
}

.emphasizedSignInText {
  display: block;
  white-space: nowrap;
}

// DEFAULT STYLES:
button.defaultIcon {
  border: unset;
  padding: unset;
  display: flex;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: $gi-white;
  }

  @media #{$large-up} {
    display: none;
  }
}

.defaultSignInIcon svg {
  transform: scale(1.05);

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    fill: $purple;
  }

  @media #{$large-up} {
    display: none;
  }
}

.defaultEmphasizedSignInIcon svg {
  display: none;
}

button.container {
  background-color: transparent;
  border: 0;
  font-size: $getty-header-font-size; // override alliance ui button font size
  // margin-right: 1.25em;
  padding: 0;
  line-height: unset;
  height: unset;
  color: $gi-black;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: transparent;
    color: $getty-link-hover-color;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 0.3s;

    svg {
      fill: $getty-link-hover-color;
      transition-duration: 0.3s;
    }
  }

  @media #{$large-up} {
    display: inline-flex;

    button.container svg {
      margin-right: 0.8em;
      transform: scale(1.05);
    }
  }
}

button.gpsContainer {
  background-color: transparent;
  border: 0;
  font-size: $getty-header-font-size; // override alliance ui button font size
  // margin-right: 1.25em;
  padding: 0;
  line-height: unset;
  height: unset;
  color: $gi-white;
  padding-right: 40px;
  padding-bottom: 10px;
  svg {
    fill: $gi-white;
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: transparent;
    color: $gi-white;
    cursor: pointer;
    text-decoration: none;

    svg {
      fill: $gi-white;
    }
  }

  @media #{$large-up} {
    display: inline-flex;

    button.container svg {
      margin-right: 0.8em;
      transform: scale(1.05);
    }
  }
}

.signInName {
  display: none;

  @media #{$large-up} {
    margin-left: 0.625em;
    display: block;
  }
}

.signInIcon {
  display: flex;
  cursor: pointer;
}

.signInIcon svg {
  margin-left: 0.5em;
  transform: scale(1.05);

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    fill: $purple;
  }
}

a.defaultSignInLink {
  display: none;

  @media #{$large-up} {
    display: flex;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid $gi-black;
    font-size: $getty-header-font-size;
    padding: 12px 16px;
    color: $gi-black;

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      cursor: pointer;
      color: $gi-white;
      background-color: $gi-black;
    }
  }
}

a.defaultEmphasizedSignInLink {
  display: flex;
  white-space: nowrap;
  background-color: $purple;
  border: 1px solid $purple-darker;
  font-size: $getty-header-font-size;
  padding: 12px 16px;
  color: $gi-white;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    color: $gi-white;
    background-color: $purple-darker;
  }
}

// VISUAL GPS Styles
button.visualGpsIcon {
  margin-bottom: 1.5em;
  margin-right: 0.75em;
  border: unset;
  padding: unset;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: $gi-black;
  }

  @media #{$large-up} {
    display: none;
  }
}

.visualGpsSignInIcon,
.visualGpsEmphasizedSignInIcon svg {
  fill: $gi-white;
  margin-right: 2em;
  margin-top: 1em;
  transform: scale(1.1);

  @media #{$large-up} {
    display: none;
  }
}

a.visualGpsSignInLink,
a.visualGpsEmphasizedSignInLink {
  display: none;

  @media #{$large-up} {
    display: flex;
    background-color: $gi-black;
    border: 1px solid $gi-white;
    color: $gi-white;
    font-size: $getty-header-font-size;
    margin-right: 2em;
    padding: 12px 18px;

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      cursor: pointer;
      background-color: $gi-white;
      color: $gi-black;
    }
  }
}
