@use '@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty/index' as gettyTheme;
@import '../../Header.config';

@keyframes delayed-hidden {
  99% {
    visibility: visible;
    height: auto;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.panel {
  width: $sidebar-meganav-mobile-width;
  height: 100%;
  overflow: auto;

  @media #{$medium-up} {
    width: $sidebar-meganav-tablet-width;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .delayedHidden {
    animation: none;
  }
}

.delayedHidden {
  animation: 0s linear $sidebar-meganav-slide-duration forwards delayed-hidden;
}
