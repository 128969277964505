@import '../Header.config';

.hamburgerContainer {
  @media #{$large-up} and (hover: hover) {
    display: none;
  }
}

.logoContainer {
  margin-left: 19px;
}

button.boardsButton,
a.aiLink,
a.cartLink {
  padding: 0 $nav-link-padding-x-mobile;
  font-size: $getty-header-font-size-mobile;
  font-weight: 500;

  @media #{$large-up} {
    font-size: $getty-header-font-size;
    font-weight: 400;
  }

  @media #{$large-up} and (hover: hover) {
    padding: 0 $nav-link-padding-x;
  }
}

.accountButtonContainer {
  margin-left: $nav-link-padding-x;

  @media #{$large-up} {
    margin-left: $nav-link-padding-x-mobile;
  }

  button {
    position: relative;
    top: 1px;
  }
}

.largeUp {
  display: none;

  @media #{$large-up} {
    display: inline-flex;
  }
}

.tablet {
  display: none;

  @media #{$medium-up} {
    display: inline-flex;
  }

  @media #{$large-up} and (hover: hover) {
    display: none;
  }
}

.mobileAndTablet {
  display: inline-flex;

  @media #{$large-up} and (hover: hover) {
    display: none;
  }
}
