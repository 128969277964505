@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;
@import '../Header.config';

.container {
  padding: 24px;
  flex-shrink: 0;

  &.isDesktop {
    @media #{$xlarge-up} {
      flex-shrink: 0;
      padding: 32px 16px 48px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
}

.title {
  font-family: $primary-font-family;
  font-size: 16px;
  line-height: normal;
}

.icon {
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 8px;
  fill: commonStyles.$true-black;
}

.pillContainer {
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  width: fit-content;
  margin: -8px 0 0 -8px;
  padding-top: 12px;
  overflow: hidden;
  font-size: 14px;

  .isDesktop & {
    @media #{$large-up} {
      max-height: 215px; // This is to prevent every item in the array from showing per figma design
      font-size: inherit;
    }

    @media #{$xlarge-up} {
      max-height: none;
    }
  }
}

button.viewAll {
  display: flex;
  border-radius: 8px;
  border: 1px solid gettyTheme.$medium-grey;
  margin: 8px 0 0 8px;
  padding: 12px;
  color: gettyTheme.$primary;
  width: fit-content;
  font-size: inherit;
  letter-spacing: 0;
  line-height: 1.15;
  cursor: pointer;
  text-transform: none;
  flex-wrap: nowrap;

  .isDesktop & {
    @media #{$large-up} {
      display: none;
    }

    @media #{$xlarge-up} {
      display: flex;
      font-size: 14px;
    }
  }
}

button.viewMore {
  text-transform: none;
  border: none;
  display: none;
  margin: 8px 0 0 8px;
  padding: 12px;
  width: fit-content;
  font-size: 14px;
  line-height: 1.15;
  cursor: pointer;

  .isDesktop & {
    @media #{$large-up} {
      display: flex;
    }

    @media #{$xlarge-up} {
      display: none;
    }
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    text-decoration: none;
    background-color: gettyTheme.$light-grey;
    color: commonStyles.$true-black;
  }
}

button.viewMoreText {
  flex-wrap: nowrap;
  color: gettyTheme.$primary;
  letter-spacing: 0;

  .isDesktop & {
    @media #{$xlarge-up} {
      display: none;
    }
  }
}
