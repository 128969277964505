@import './Header.config';

.container {
  position: relative;

  // z-index of SRP filter panel is 13, this needs to be less than that to prevent the hamburger icon from overlaying the panel
  z-index: 12;
  font-size: $getty-header-font-size;
}

.desktopLabel {
  margin-left: em(10px);
  display: none;

  @media #{$large-up} {
    display: flex;
  }
}
