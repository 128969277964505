@import '../Header.config';

a.link {
  // overrides alliance ui link classes
  border: 0;
  color: $black;
  display: inline-flex;
  padding: 0;
  align-items: center;

  svg {
    margin-right: 0;
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    color: $getty-link-hover-color;
    text-decoration: none;
    transition-duration: 0.3s;

    svg {
      path {
        fill: $getty-link-hover-color;
        transition-duration: 0.3s;
      }
    }
  }
}

.aiGeneratorText {
  margin-left: 0.8em;
  padding-top: 0.01em;
  white-space: nowrap;
}
