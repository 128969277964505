@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@import '../Header.config';

.navBar {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: $nav-bar-padding-y 16px $nav-bar-padding-y 24px;
  background: commonStyles.$true-white;

  > ul {
    align-items: stretch;
    margin-bottom: 0; // Remove bootstrap ul margin
  }
}

.primary {
  min-height: 60px;
}

.secondary {
  display: none;
  min-height: 48px;

  @media #{$large-up} and (hover: hover) {
    display: flex;
  }
}
