@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@import '../Header.config';

.dropdown {
  position: absolute;
  background: commonStyles.$true-white;
  border-radius: 0 0 24px 24px;
  top: 100%;
  left: 0;
  right: auto;
  width: 256px;
  padding-top: 12px;
  margin-top: $nav-bar-padding-y;
  overflow: hidden;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 20px;
    border-top: 1px solid rgb(0 0 0 / 11%);
    background: linear-gradient(rgb(0 0 0 / 10%) 0%, rgb(0 0 0 / 0%) 100%);
    pointer-events: none;
  }

  .item.hasFullWidthDropdown & {
    margin-top: 0;
    right: 0;
    width: 100%;
    padding-top: 0;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }

  &.hasFullWidthDropdown {
    position: static;
  }
}

.childrenWrapper {
  position: relative;
  display: flex;

  button {
    text-transform: none;
  }
}

.hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
}
